<template>
  <!--  <v-container class="line-item-detail-new"> -->
  <v-row>
    <v-col md="12">
      <table class="line-item-multiple-table" width="100%">
        <thead>
          <tr class="custom-border">
            <th class="text-left border-right-new">Delivery #</th>
            <th class="text-left border-right-new">Date</th>
            <th class="text-left border-right-new" width="20%">Drivers</th>
            <th class="text-left border-right-new" with="100">
              Total To Be Delivered
            </th>
            <th class="text-left border-right-new">Total Rejected</th>
            <th class="text-left border-right-new">Total Delivered</th>
            <!-- <th class="text-left" width="25%">
                            &nbsp;&nbsp;&nbsp;Item
                        </th> -->
            <th class="text-left border-right-new">Status</th>

            <th class="text-left">File</th>
            <!--  <th class="text-left">&nbsp;&nbsp;&nbsp;Balance</th> -->
          </tr>
        </thead>
        <tbody>
          <template v-if="deliveryLineIteams && deliveryLineIteams.length > 0">
            <template v-for="(line_item, index) in deliveryLineIteams">
              <tr
                :key="index"
                class="line-item-listing-row"
                :class="{ 'custom-border-top': index > 0 }"
                v-on:click="deliveryDetails(line_item.id, index)"
              >
                <td valign="top" class="text-left border-right-new">
                  <v-chip
                    v-if="line_item && line_item.barcode"
                    small
                    label
                    class="mt-2 custom-status font-weight-600 custom-grey-border text-uppercase"
                    text-color=""
                    outlined
                    color="orange"
                  >
                    {{ parentDetail.barcode }}-0{{ index + 1
                    }}<!-- {{
                      getBarcode(line_item.barcode) 
                    }}-->
                  </v-chip>
                </td>
                <td valign="top" class="text-left border-right-new">
                  <v-chip
                    small
                    label
                    class="mt-2 custom-status font-weight-600 custom-grey-border text-uppercase"
                    text-color=""
                    color="green"
                    outlined
                  >
                    {{ formatDate(line_item.added_at) }}
                  </v-chip>
                </td>
                <td valign="top" class="text-left border-right-new" width="20%">
                  <template v-for="(row, CIndex) in line_item['drivers']">
                    <v-chip
                      class="mb-1 mr-1"
                      :key="CIndex"
                      v-if="row && row.engineerData"
                      ><v-avatar left small>
                        <img
                          v-if="
                            row &&
                            row.engineerData.profile_logo &&
                            row.engineerData.profile_logo.file
                          "
                          :src="row.engineerData.profile_logo.file.url"
                        />
                        <img
                          v-else
                          src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png"
                        />
                      </v-avatar>
                      <span>{{ row.engineerData.display_name }}</span>
                    </v-chip>
                  </template>
                </td>
                <td valign="top" class="text-left border-right-new">
                  <v-chip
                    label
                    class="chip-custom-font"
                    color="blue"
                    text-color=""
                    outlined
                  >
                    {{ getTotalToBeDeliverd(line_item.parent) }}
                  </v-chip>
                </td>
                <td valign="top" class="text-left border-right-new">
                  <v-chip
                    label
                    class="chip-custom-font"
                    color="red"
                    text-color=""
                    outlined
                  >
                    {{ getTotalRejected(line_item.parent) }}
                  </v-chip>
                </td>
                <td valign="top" class="text-left border-right-new">
                  <v-chip label class="chip-custom-font" color="green" outlined>
                    {{ getTotalDeliverd(line_item.parent) }}
                  </v-chip>
                </td>
                <td valign="top" class="text-left border-right-new">
                  <template
                    v-if="
                      line_item.type_value == 'delivery-order' ||
                      line_item.type_value == 'partial'
                    "
                  >
                    <v-chip
                      small
                      label
                      class="mt-2 custom-status font-weight-600 custom-grey-border"
                      text-color="white"
                      color="orange darken-4"
                    >
                      Partially Delivered
                    </v-chip>
                  </template>
                  <template v-if="line_item.type_value == 'failed'">
                    <v-chip
                      small
                      label
                      class="mt-2 custom-status font-weight-600 custom-grey-border"
                      text-color="white"
                      color="red darken-4"
                    >
                      Failed
                    </v-chip>
                  </template>
                  <template v-if="line_item.type_value == 'full'">
                    <v-chip
                      small
                      label
                      class="mt-2 custom-status font-weight-600 custom-grey-border"
                      text-color="white"
                      color="green darken-4"
                    >
                      Fully Delivered
                    </v-chip>
                  </template>
                  <template v-if="line_item.type_value == 'rejected'">
                    <v-chip
                      small
                      label
                      class="mt-2 custom-status font-weight-600 custom-grey-border"
                      text-color="white"
                      color="red darken-4"
                    >
                      Rejected
                    </v-chip>
                  </template>
                </td>
                <td valign="top" class="text-left border-right-new">
                  <v-tooltip top content-class="custom-top-tooltip">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        :disabled="!line_item.pdf_url"
                        color="red"
                        v-on:click.stop.prevent="
                          downloadFile(line_item.pdf_url)
                        "
                        icon
                      >
                        <v-icon>mdi-file-pdf</v-icon>
                      </v-btn>
                    </template>
                    <span>Download PDF</span>
                  </v-tooltip>

                  <!--  <p class="m-0 p-2 custom-grey-border">
                                    {{ line_item.pending_qty }}
                                </p> -->
                </td>
              </tr>
            </template>
          </template>
          <template v-else>
            <tr>
              <td :colspan="10" class="py-4">
                <p
                  class="m-0 row-not-found text-center font-weight-500 font-size-16"
                >
                  <img
                    :src="$assetURL('media/error/empty.png')"
                    class="row-not-found-image"
                  />
                  Uhh... There are no delivery at the moment.
                </p>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </v-col>
    <Dialog :common-dialog="delivery_item_dialog" :dialog-width="960">
      <template v-slot:title
        >Delivery Detail :-
        <v-chip
          v-if="deliveryDetailData && deliveryDetailData.barcode"
          label
          color="chip-custom-blue"
          outlined
          class="text-white p-3"
          style="font-size: 21px !important; font-weight: 700"
        >
          <!-- {{ parentDetail.barcode }}-
          {{ getDetailBarcode(deliveryDetailData.barcode) }} -->
          {{ newbarcode }}
        </v-chip>
        <template
          v-if="
            deliveryDetailData.type_value == 'delivery-order' ||
            deliveryDetailData.type_value == 'partial'
          "
        >
          <v-chip
            label
            class="ml-2 font-weight-600 custom-grey-border"
            text-color="white"
            color="orange darken-4"
          >
            Partially Delivered
          </v-chip>
        </template>
        <template v-if="deliveryDetailData.type_value == 'rejected'">
          <v-chip
            label
            class="ml-2 font-weight-600 custom-grey-border"
            text-color="white"
            color="red"
          >
            Rejected
          </v-chip>
        </template>
        <template v-if="deliveryDetailData.type_value == 'full'">
          <v-chip
            label
            class="ml-2 font-weight-600 custom-grey-border"
            text-color="white"
            color="green darken-4"
          >
            Fully Delivered
          </v-chip>
        </template>
      </template>
      <template v-slot:body>
        <v-col md="12" class="px-5">
          <v-row>
            <v-col md="12">
              <table
                width="100%"
                border="0"
                cellspacing="0"
                cellpadding="4"
                class="custom-background"
              >
                <tr>
                  <th class="pa-2 font-weight-700" valign="top" width="100">
                    Date
                  </th>
                  <td class="pa-2" width="5%"></td>
                  <td class="pa-2">
                    <v-chip
                      small
                      label
                      class="custom-status font-weight-600 custom-grey-border text-uppercase"
                      text-color=""
                      color="green"
                      outlined
                    >
                      {{ formatDate(deliveryDetailData.added_at) }}
                    </v-chip>
                  </td>
                </tr>
                <tr>
                  <th class="pa-2 font-weight-700" width="100" valign="top">
                    Drivers
                  </th>
                  <td class="pa-2" width="5%"></td>
                  <td class="pa-2">
                    <template
                      v-for="(row, CIndex) in deliveryDetailData['drivers']"
                    >
                      <v-chip
                        class="mb-1 mr-1"
                        :key="CIndex"
                        v-if="row && row.engineerData"
                        ><v-avatar left small>
                          <img
                            v-if="
                              row &&
                              row.engineerData.profile_logo &&
                              row.engineerData.profile_logo.file
                            "
                            :src="row.engineerData.profile_logo.file.url"
                          />
                          <img
                            v-else
                            src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png"
                          />
                        </v-avatar>
                        <span>{{ row.engineerData.display_name }}</span>
                      </v-chip>
                    </template>
                  </td>
                </tr>
                <tr>
                  <th class="pa-2 font-weight-700" valign="top" width="100">
                    Total items
                  </th>
                  <td class="pa-2" width="5%"></td>
                  <td class="pa-2">
                    <v-chip
                      label
                      outlined
                      text-color=""
                      color="blue"
                      class="px-2 chip-custom-font"
                    >
                      {{ deliveryDetailData.total_items }}
                    </v-chip>
                  </td>
                </tr>
                <tr>
                  <th class="pa-2 font-weight-700" valign="top" width="100">
                    File
                  </th>
                  <td class="pa-2" width="5%"></td>
                  <td class="pa-2">
                    <v-tooltip top content-class="custom-top-tooltip">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          :disabled="!deliveryDetailData.pdf_url"
                          color="red"
                          v-on:click.stop.prevent="
                            downloadFile(deliveryDetailData.pdf_url)
                          "
                          icon
                        >
                          <v-icon>mdi-file-pdf</v-icon>
                        </v-btn>
                      </template>
                      <span>Download PDF</span>
                    </v-tooltip>
                  </td>
                </tr>
              </table>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="12" class="mb-5">
              <table class="line-item-multiple-table" width="100%">
                <thead
                  class="custom-border"
                  style="background: antiquewhite !important"
                >
                  <th class="pa-2 text-left border-right-new" width="5%">#</th>
                  <th class="pa-2 text-left border-right-new">Image</th>
                  <th class="pa-2 text-left border-right-new">Product</th>
                  <th class="pa-2 text-left border-right-new">
                    Initial Quantity
                  </th>
                  <th class="pa-2 text-left border-right-new" with="100">
                    To Be Delivered
                  </th>
                  <th class="pa-2 text-left border-right-new" width="150">
                    Rejected Qty
                  </th>
                  <th class="pa-2 text-left border-right-new" width="150">
                    Delivered Qty
                  </th>
                  <th class="pa-2 text-left border-right-new" width="150">
                    Balance Qty
                  </th>
                </thead>
                <tbody>
                  <!-- <tr v-for="(row, index) in grnDetail.line_items" :key="index"> -->
                  <tr
                    class="line-item-listing-row border-bottom-new"
                    v-for="(row, index) in deliveryDetailData.parent"
                    :key="index"
                  >
                    <td
                      class="text-left border-right-new font-weight-600 text-color-black"
                      width="5%"
                    >
                      {{ index + 1 }}
                    </td>
                    <td
                      class="text-left border-right-new font-weight-600 text-color-black"
                    >
                      <v-avatar class="avatar-custom">
                        <v-img
                          v-if="row && row.product"
                          class="customer-image"
                          width="70"
                          height="70"
                          contain
                          :lazy-src="$defaultImage"
                          :src="getProductImage(row.product)"
                        >
                        </v-img>
                      </v-avatar>
                    </td>
                    <td
                      class="text-left border-right-new font-weight-600 text-color-black"
                    >
                      {{ row.product.barcode }}
                    </td>
                    <td
                      class="text-left border-right-new font-weight-600 text-color-black"
                    >
                      <v-chip
                        text-color=""
                        color="black"
                        outlined
                        label
                        class="chip-custom-font"
                      >
                        {{ row.line_item.quantity }}
                      </v-chip>
                    </td>
                    <td
                      class="text-left border-right-new font-weight-600 text-color-black"
                    >
                      <v-chip
                        label
                        class="chip-custom-font"
                        color="blue"
                        text-color=""
                        outlined
                      >
                        {{ totalQuntity(row) }}
                      </v-chip>
                    </td>
                    <td
                      class="text-left border-right-new font-weight-600 text-color-black"
                    >
                      <v-chip
                        outlined
                        label
                        text-color=""
                        color="red"
                        class="chip-custom-font"
                      >
                        {{ row.rejected_qty }}
                      </v-chip>
                    </td>
                    <td
                      class="text-left border-right-new font-weight-600 text-color-black"
                    >
                      <v-chip
                        outlined
                        label
                        text-color=""
                        color="green"
                        class="chip-custom-font"
                      >
                        {{ row.deliverd_qty }}
                      </v-chip>
                    </td>
                    <td
                      class="text-left border-right-new font-weight-600 text-color-black"
                    >
                      <v-chip
                        outlined
                        label
                        text-color=""
                        color="orange"
                        class="chip-custom-font"
                      >
                        {{ getBalance(row) }}
                      </v-chip>
                    </td>
                  </tr>
                </tbody>
              </table>
            </v-col>
          </v-row>
        </v-col>
      </template>
      <template v-slot:action>
        <v-btn
          v-on:click="delivery_item_dialog = false"
          class="mx-2 custom-grey-border custom-bold-button"
        >
          Close
        </v-btn>
      </template>
    </Dialog>
  </v-row>
</template>
<script>
import {
  GET,
  /* QUERY,
    PATCH,
    POST,
    PUT, */
} from "@/core/services/store/request.module";
import Dialog from "@/view/pages/partials/Dialog";
import CommonMixin from "@/core/plugins/common-mixin";
import { DeliveryEventBus } from "@/core/lib/delivery.lib.js";
import { reduce, toNumber } from "lodash";
/* import QuantityInput from "@/view/components/QuantityInput.vue"; */
export default {
  mixins: [CommonMixin],
  props: {
    parentDetail: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      deliveryLineIteams: [],
      pageLoading: false,
      delivery_item_dialog: false,
      newbarcode: null,
      deliveryDetailData: {},
    };
  },
  methods: {
    totalQuntity(row) {
      let total = row.deliverd_qty + row.rejected_qty;
      return total;
    },
    getBalance(row) {
      let total = this.totalQuntity(row) - row.deliverd_qty;
      if (total < 0) {
        total = 0;
      }
      return total;
    },
    /*     getTotalRejected(row){
           console.log(row,'row')
        }, */

    downloadFile(url, prepend_url) {
      if (!url) {
        return false;
      }
      if (prepend_url) {
        url = `https://bthrust-fsm.s3.ap-southeast-1.amazonaws.com/${url}`;
      }
      window.open(url, "_blank");
    },
    getTotalRejected(data) {
      let result = reduce(
        data,
        (acc, row) => {
          acc = toNumber(acc) + toNumber(row.rejected_qty);
          return acc;
        },
        0
      );
      return result;
    },
    getTotalDeliverd(data) {
      let result = reduce(
        data,
        (acc, row) => {
          acc = toNumber(acc) + toNumber(row.deliverd_qty);
          return acc;
        },
        0
      );
      return result;
    },
    getTotalToBeDeliverd(data) {
      let result = reduce(
        data,
        (acc, row) => {
          acc = toNumber(acc) + toNumber(row.deliverd_qty);
          return acc + row.rejected_qty;
        },
        0
      );
      return result;
    },
    getBarcode(chlidBarcode) {
      if (chlidBarcode) {
        let lastnumber = chlidBarcode.slice(-2);
        return lastnumber;
      } else {
        return false;
      }
    },
    getDetailBarcode(chlidBarcode) {
      if (chlidBarcode) {
        let lastnumber = chlidBarcode.slice(-2);
        return lastnumber;
      } else {
        return false;
      }
    },
    getDeliveryOrderLineItem() {
      this.pageLoading = true;
      this.$store
        .dispatch(GET, {
          url: "delivery-order-line-item/" + this.parentDetail.id,
        })
        .then(({ data }) => {
          this.deliveryLineIteams = data;
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.pageLoading = false;
        });
    },
    deliveryDetails(id, index) {
      this.newbarcode = `${this.parentDetail.barcode}-0${
        index + 1
      }`; /* - index  */
      this.delivery_item_dialog = true;
      this.$store
        .dispatch(GET, {
          url: "delivery-order-detail/" + id,
        })
        .then(({ data }) => {
          this.deliveryDetailData = data;
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.pageLoading = false;
        });
    },
    getProductImage(product_logo) {
      if (
        product_logo &&
        product_logo.product_image &&
        product_logo.product_image.file &&
        product_logo.product_image.file.url
      ) {
        return product_logo.product_image.file.url;
      }
      return null;
    },
  },
  mounted() {
    if (this.parentDetail.id > 0) {
      this.getDeliveryOrderLineItem();
    }
    DeliveryEventBus.$on("update:delivery", () => {
      this.getDeliveryOrderLineItem();
    });
  },
  components: {
    /*  QuantityInput */
    Dialog,
  },
};
</script>
